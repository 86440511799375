import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'room',
    loadChildren: () => import('./pages/room/room.module').then(m => m.RoomPageModule)
  },
  {
    path: 'building-function',
    loadChildren: () => import('./pages/building-function/building-function.module').then(m => m.BuildingFunctionPageModule)
  },
  {
    path: 'scene',
    loadChildren: () => import('./pages/scene/scene.module').then(m => m.ScenePageModule)
  },
  {
    path: 'favourite',
    loadChildren: () => import('./pages/favourite/favourite.module').then(m => m.FavouritePageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: 'light',
    loadChildren: () => import('./pages/light/light.module').then( m => m.LightPageModule)
  },
  {
    path: 'switch',
    loadChildren: () => import('./pages/switch/switch.module').then( m => m.SwitchPageModule)
  },
  {
    path: 'shutter',
    loadChildren: () => import('./pages/shutter/shutter.module').then( m => m.ShutterPageModule)
  },
  {
    path: 'temperature',
    loadChildren: () => import('./pages/temperature/temperature.module').then( m => m.TemperaturePageModule)
  },
  {
    path: 'security',
    loadChildren: () => import('./pages/security/security.module').then( m => m.SecurityPageModule)
  },
  {
    path: 'light-details',
    loadChildren: () => import('./pages/light-details/light-details.module').then( m => m.LightDetailsPageModule)
  },
  {
    path: 'consumption',
    loadChildren: () => import('./pages/consumption/consumption.module').then( m => m.ConsumptionPageModule)
  },
  {
    path: 'consumption-details',
    loadChildren: () => import('./pages/consumption-details/consumption-details.module').then( m => m.ConsumptionDetailsPageModule)
  },
  {
    path: 'water-softening-details',
    loadChildren: () => import('./pages/water-softening-details/water-softening-details.module').then( m => m.WaterSofteningDetailsPageModule)
  },
  {
    path: 'shutter-details',
    loadChildren: () => import('./pages/shutter-details/shutter-details.module').then( m => m.ShutterDetailsPageModule)
  },
  {
    path: 'sprinkler',
    loadChildren: () => import('./pages/sprinkler/sprinkler.module').then( m => m.SprinklerPageModule)
  },
  {
    path: 'pool',
    loadChildren: () => import('./pages/pool/pool.module').then( m => m.PoolPageModule)
  },
  {
    path: 'vent',
    loadChildren: () => import('./pages/vent/vent.module').then( m => m.VentPageModule)
  },
  {
    path: 'vent-details',
    loadChildren: () => import('./pages/vent-details/vent-details.module').then( m => m.VentDetailsPageModule)
  },
  {
    path: 'audio',
    loadChildren: () => import('./pages/audio/audio.module').then( m => m.AudioPageModule)
  },
  {
    path: 'audio-details',
    loadChildren: () => import('./pages/audio-details/audio-details.module').then( m => m.AudioDetailsPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
