import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Storage } from '@ionic/storage';
import { SwUpdate } from '@angular/service-worker';
import { get, set } from 'idb-keyval';
import { IoBrokerService } from './service/io-broker.service';
import { Router } from '@angular/router';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Einstellungen',
      url: '/settings',
      icon: 'settings'
    },
    // {
    //   title: 'Inbox',
    //   url: '/inbox',
    //   icon: 'mail'
    // }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private storage: Storage,
    private toastController: ToastController,
    private swUpdate: SwUpdate,
    private alertController: AlertController,
    private ioBrokerService: IoBrokerService,
    private navController: NavController,
    private router: Router,
    private backgroundMode: BackgroundMode
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.splashScreen.hide();

      // this.backgroundMode.disableWebViewOptimizations();

      // this.backgroundMode.enable();
      // this.backgroundMode.disableWebViewOptimizations();

      // this.backgroundMode.on('activate').subscribe(r => {
      //   //yes work
      //   alert('ON ACTIVATE');
      // });

      // this.backgroundMode.on('deactivate').subscribe(r => {
      //   //yes work
      //   alert('ON DEACTIVATE');
      // });

      // window.addEventListener("focus", () => {
      //   // this.ioBrokerService.connect();
      // });

      this.initBackgroundMode(this);

      this.ioBrokerService.connect();

      this.showIosInstallBanner();
      this.checkUpdate();

      setInterval(() => {
        this.swUpdate.checkForUpdate().then(res => {
          // console.log(res);
        }).catch(checkForUpdateError => {
          alert(checkForUpdateError);
        });
      }, 10000); // 10s
    });
  }

  initBackgroundMode(thisInstance) {
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'hidden') {
      }

      if (document.visibilityState === 'visible') {
        try {
          thisInstance.ioBrokerService.isConnected.subscribe((value) => {
            if (true === value) {
              // alert(true);
            } else {
              // alert(false);
            }
          });
        } catch (error) {
          alert(error);
        }
      }
    });

  }

  ngOnInit() {
    const path = window.location.pathname.split('/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  async showIosInstallBanner() {
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };  // Detects if device is in standalone mode
    // tslint:disable-next-line:no-string-literal
    const isInStandaloneMode = () => ('standalone' in window.navigator) && window.navigator['standalone'];

    // Show the banner once
    let isBannerShown = await get('isBannerShown');

    isBannerShown = undefined;

    // Checks if it should display install popup notification
    if (isIos() && !isInStandaloneMode() && isBannerShown === undefined) {
      const toast = await this.toastController.create({
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: () => {
            }
          }
        ],
        cssClass: 'custom-toast',
        position: 'bottom',
        message: `SmartHome App installieren, Auf "TEILEN" tippen und anschließend "Zum Home-Bildschirm" wählen`,
      });
      toast.present();
      set('isBannerShown', true);
    }
  }

  checkUpdate_() {
    if (this.swUpdate.isEnabled) {

      this.swUpdate.available.subscribe(async () => {
        const alert = await this.alertController.create({
          header: `Neues Update verfügbar`,
          message: `Möchten Sie das Update jetzt installieren?`,
          buttons: [
            {
              text: 'Nein',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
              }
            }, {
              text: 'Ja',
              handler: () => {
                window.location.reload();

                // this.router.navigate(['tabs/home']).then(() => {
                //   window.location.reload();
                // });
              },
            },
          ],
        });
        await alert.present();
      });
    }
  }

  checkUpdate() {
    this.storage.get('isPromptUpdate').then((isPrompt) => {

      if (this.swUpdate.isEnabled && isPrompt == false) {

        this.storage.set('isPromptUpdate', true).then(() => {
          this.swUpdate.available.subscribe(async () => {
            const alert = await this.alertController.create({
              header: `Neues Update verfügbar`,
              message: `Möchten Sie das Update jetzt installieren?`,
              buttons: [
                {
                  text: 'Nein',
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: () => {
                    this.storage.set('isPromptUpdate', false);
                  }
                }, {
                  text: 'Ja',
                  handler: () => {
                    this.storage.set('isPromptUpdate', false);
                    window.location.reload();

                    // this.router.navigate(['tabs/home']).then(() => {
                    //   window.location.reload();
                    // });
                  },
                },
              ],
            });
            await alert.present();
          });
        });
      }
    });
  }

}
