import { Injectable } from '@angular/core';
import { NgxMatIoBrokerConnectorService } from 'ngx-mat-io-broker-connector-service';
import { BehaviorSubject } from 'rxjs';
import { Constants } from '../constants';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class IoBrokerService {
  public isConnected: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    public ioBrokerConn: NgxMatIoBrokerConnectorService,
    private logger: LogService
  ) {
    this.logger.info("IoBrokerService", "constructor", "");
  }

  connect() {
    this.logger.info("IoBrokerService", "connect", "");

    return new Promise((resolve, reject) => {
      this.ioBrokerConn.connect(Constants.IP, Constants.Port, Constants.Protocol).then(() => {
        this.logger.info("IoBrokerService", "connect", "connect successfully");
        this.isConnected.next(true);
        
        resolve(true);
      }).catch(error => {
        this.logger.error("IoBrokerService", "connect", "connect failed", error);
        this.isConnected.next(false);

        reject(false);
      });
    });
  }


}
