import { Injectable } from '@angular/core';

// https://www.codemag.com/article/1711021/Logging-in-Angular-Applications
export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6
}

@Injectable({
  providedIn: 'root'
})
export class LogService {

  level: LogLevel = LogLevel.All;
  logWithDate: boolean = true;

  constructor() { }

  debug(type: string, method: string, msg: string, ...optionalParams: any[]) {
    this.writeToLog(type, method, msg, LogLevel.Debug, optionalParams);
  }

  info(type: string, method: string, msg: string, ...optionalParams: any[]) {
    this.writeToLog(type, method, msg, LogLevel.Info, optionalParams);
  }

  warn(type: string, method: string, msg: string, ...optionalParams: any[]) {
    this.writeToLog(type, method, msg, LogLevel.Warn, optionalParams);
  }

  error(type: string, method: string, msg: string, ...optionalParams: any[]) {
    this.writeToLog(type, method, msg, LogLevel.Error, optionalParams);
  }

  fatal(type: string, method: string, msg: string, ...optionalParams: any[]) {
    this.writeToLog(type, method, msg, LogLevel.Fatal, optionalParams);
  }

  log(type: string, method: string, msg: string, ...optionalParams: any[]) {
    this.writeToLog(type, method, msg, LogLevel.All, optionalParams);
  }

  private writeToLog(type: string, method: string, msg: string, level: LogLevel, params: any[]) {
    if (this.shouldLog(level)) {
      let value: string = "";

      // Build log string
      if (this.logWithDate) {
        value = new Date() + " - ";
      }

      value += "Type: " + type + " - ";
      value += "Method: " + method + " - ";

      value += "Log Level: " + LogLevel[this.level];

      if (msg)
        value += " - Message: " + msg;

      if (params.length) {
        value += " - Extra Info: " + this.formatParams(params);
      }

      // Log the value
      console.log(value);
    }
  }

  private shouldLog(level: LogLevel): boolean {
    let ret: boolean = false;
    if ((level >= this.level && level !== LogLevel.Off) || this.level === LogLevel.All) {
      ret = true;
    }
    return ret;
  }

  private formatParams(params: any[]): string {
    let ret: string = params.join(",");

    // Is there at least one object in the array?
    if (params.some(p => typeof p == "object")) {
      ret = "";

      // Build comma-delimited string
      for (let item of params) {
        ret += JSON.stringify(item) + ",";
      }
    }
    return ret;
  }




}
